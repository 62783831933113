import { Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <footer
      style={{
        padding: "2vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#8D9196",
        color: "#F2F5F7",
        width: "100%",
        marginTop: "auto",
      }}
    >
      <Typography variant="body2" gutterBottom>
        &copy; {new Date().getFullYear()} Web3 Connect. All rights reserved.{" "}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <a
          href="/privacy-policy"
          style={{
            textDecoration: "none",
            color: "#F2F5F7",
            fontSize: "0.85rem",
          }}
        >
          Privacy notice
        </a>
      </Typography>
    </footer>
  );
};

export default Footer;
