import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.gif";

const About = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Box my={3} pt={8}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Innovate, Inspire, and Lead the Way!
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Redefine the Web's Future with Us!
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" my={3}>
          <img
            src={logo}
            alt="loading..."
            style={{
              maxWidth: "40%",
              height: "auto",
              maxHeight: "300px",
              objectFit: "contain",
              transition: "transform 0.3s ease-in-out",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.transform = "scale(1.2)")
            }
            onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
          />
        </Box>
        <Typography
          variant="body1"
          align="center"
          style={{ marginTop: "1rem" }}
        >
          Our vision is to create a secure web that ensures user privacy, data
          control for users, and empowers everyone to have an impact on the
          decisions that shape our world. We believe in a decentralized future
          where individuals have the power to control their own data and
          privacy, and where transparency and security are at the forefront of
          technological advancements.
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{ marginTop: "1rem" }}
        >
          We specialize in providing exceptional services in Web3 technologies.
          Our expertise includes decentralized applications (DApps), security
          audits, integrations with Web 2.0 and other technologies, as well as
          various industrial use cases. We are passionate about supporting our
          clients and partners. Our mission is to guide you through the
          complexities of blockchain technology, ensuring that your projects are
          secure and scalable. Whether you are a startup or an established
          business, we have the knowledge and experience to support your growth
          and drive innovation.
          <br />
          Reach out to us today to learn how we can help you achieve your goals.
          We look forward to collaborating with you and bringing your vision to
          life. We are based in Oslo - Norway and have a global reach. Whether
          you want to talk about a project or just want to say hi, we are always
          happy to hear from you. Contact us at: contact@web3connect.online or
          register on our website.
        </Typography>

        <Box display="flex" justifyContent="center" my={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/get-in-touch")}
          >
            Register Now
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default About;
