import React from "react";
import { Typography, Container } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container
      sx={{
        paddingTop: { xs: 12, md: 12, lg: 12 },
        paddingBottom: { xs: 10, md: 6, lg: 6 },
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
        Privacy Policy - Web 3.0 Connect
      </Typography>
      <Container sx={{ textAlign: "left", paddingTop: 4 }}>
        <Typography variant="body1" gutterBottom>
          At Web 3.0 Connect, we are committed to protecting your privacy and
          ensuring that your personal data is handled in a safe and responsible
          manner. This Privacy Policy outlines how we collect, use, and protect
          your personal data in compliance with the General Data Protection
          Regulation (GDPR) and other applicable laws.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Data Collection and Use
        </Typography>
        <Typography variant="body1" gutterBottom>
          We collect personal data that you provide to us when you use our
          services, such as your name, email address, telephone number, and any
          other information you choose to provide. We use this data to respond
          to your inquiries, provide our services, and improve our offerings.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Cookies
        </Typography>
        <Typography variant="body1">
          Our website uses cookies to enhance your browsing experience. Cookies
          are small text files that are stored on your device when you visit our
          website. They help us understand how you use our site and enable us to
          provide a more personalized experience. You can manage your cookie
          preferences through your browser settings.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Data Retention
        </Typography>
        <Typography variant="body1">
          We retain your personal data for as long as necessary to fulfill the
          purposes for which it was collected, or as required by law. In
          accordance with GDPR, we will securely delete your personal data after
          10 years.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Data Protection Rights
        </Typography>
        <Typography variant="body1" gutterBottom>
          Under GDPR, you have the following rights:
        </Typography>
        <ul>
          <li>
            The right to access – You have the right to request copies of your
            personal data.
          </li>
          <li>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate or complete
            information you believe is incomplete.
          </li>
          <li>
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </li>
          <li>
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </li>
          <li>
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </li>
          <li>
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </li>
        </ul>
        <Typography variant="h5" gutterBottom>
          Data Processing and Third-Party Sharing
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may share your personal data with third-party service providers to
          help us provide and improve our services. These third parties are
          required to protect your personal data and use it only for the
          purposes for which it was shared. We do not sell your personal data to
          third parties.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Security
        </Typography>
        <Typography variant="body1" gutterBottom>
          We take the security of your personal data seriously and implement
          appropriate technical and organizational measures to protect it
          against unauthorized access, loss, or destruction.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on our website.
          You are advised to review this Privacy Policy periodically for any
          changes.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any questions or concerns about our Privacy Policy or our
          data practices, please contact us at privacy@web3Connect.com.
        </Typography>
      </Container>
    </Container>
  );
};

export default PrivacyPolicy;
