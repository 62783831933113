import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#34495E", // Customize your primary color
    },
    secondary: {
      main: "#8D9196", // Customize your secondary color
    },
    background: {
      default: "#F2F5F7",
    },
  },
  typography: {
    h1: {
      fontSize: "2rem",
    },
    h6: {
      fontSize: "1.25rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          backgroundColor: "#7DBBD3",
          color: "#F2F5F7",
          "&:hover": {
            backgroundColor: "#A66CFF",
          },
        },
      },
    },
  },
});

export default theme;
