import React, { useEffect } from "react";
import "./App.css";
import CookieProvider from "./components/context/CookieProvider";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import Home from "./components/Home";
import Services from "./components/Services";
import About from "./components/About";
import Contact from "./components/Contact";
import PrivacyPolicy from "./components/PrivacyPolicy";
import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import NotFound from "./components/NotFound";
import CookieConsent from "./components/CookieConcent";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <CookieProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop />
          <div
            className="App"
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Navbar />
            <div style={{ flex: 1 }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Home />
                      <Services />
                    </>
                  }
                ></Route>
                <Route path="/home" element={<Navigate to="/" />}></Route>
                <Route path="/who-we-are!" element={<About />}></Route>
                <Route path="/get-in-touch" element={<Contact />}></Route>
                <Route
                  path="/privacy-policy"
                  element={<PrivacyPolicy />}
                ></Route>
                <Route path="*" element={<NotFound />}></Route>
              </Routes>
            </div>
            <CookieConsent />
            <Footer />
          </div>
        </Router>
      </ThemeProvider>
    </CookieProvider>
  );
}

export default App;
