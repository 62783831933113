import React from "react";
import { Container, Typography, Box } from "@mui/material";

const Service = ({ idx, title, description, image, position }) => {
  return (
    <Box
      sx={{
        height: { xs: "65vh", md: "62.5vh", lg: "55vh" },
        maxWidth: { xs: "95%", md: "85%", lg: "70%" },
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "primary",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: position === "left" ? "row" : "row-reverse",
        },
        pt: { xs: 0, md: 0 }, // Add padding-top for smaller screens
        mb: 4, // Add margin-bottom to create space between services
      }}
    >
      <Container
        key={idx}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "90%", md: "85%", lg: "70%" },
          mb: 2, // Reduce space between elements
        }}
      >
        <Typography
          variant="h3"
          component="h3"
          gutterBottom
          color="primary"
          sx={{
            fontSize: { xs: "2rem", md: "3rem", lg: "2rem" },
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="primary"
          sx={{
            fontSize: {
              xs: "1.05rem",
              md: "2rem",
              lg: "1rem",
              margin: "auto",
            },
          }}
        >
          {description}
        </Typography>
      </Container>
      <Box
        component="img"
        src={image}
        alt={title}
        sx={{
          maxWidth: { xs: "85%", md: "80%", lg: "50%" },
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          objectFit: "contain",
          pr: { xs: 1, md: 4, lg: 12 }, // Add padding-right for larger screens
          mt: 2, // Reduce space between elements
        }}
      />
    </Box>
  );
};
export default Service;
