import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { CookieContext } from "./CookieContext";

export const CookieProvider = ({ children }) => {
  const [cookies, setCookies] = useState({});

  useEffect(() => {
    const allCookies = Cookies.get();
    setCookies(allCookies);
  }, []);

  const setCookie = (name, value, options) => {
    Cookies.set(name, value, options);
    setCookies(Cookies.get());
  };

  const removeCookie = (name, options) => {
    Cookies.remove(name, options);
    setCookies(Cookies.get());
  };

  return (
    <CookieContext.Provider value={{ cookies, setCookie, removeCookie }}>
      {children}
    </CookieContext.Provider>
  );
};

export default CookieProvider;
