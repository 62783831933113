import React from "react";
import Service from "./helpers/Service.js";
import w3cServices from "../data/services.js";

// Services component that uses the Service component
const Services = () => {
  return (
    <div className="services">
      {w3cServices.map((service, idx) => (
        <Service
          key={idx}
          title={service.title}
          description={service.description}
          image={service.image}
          position={idx % 2 === 0 ? "left" : "right"} // Alternate position
        />
      ))}
    </div>
  );
};

export default Services;
