import w3sec from "../assets/w3sec.svg";
import dapp from "../assets/dapp.svg";
import industry from "../assets/industry.svg";

const w3cServices = [
  {
    title: "Decentralized Applications (DApps)",
    description:
      "Whether it's a DeFi, NFT, or DAO project, we have the expertise to bring your vision to life. " +
      "Our team specializes in the full development cycle, from ideation to deployment. " +
      "With our deep knowledge in smart contract development and auditing, " +
      "we ensure your DApp is secure and scalable on any blockchain network of your choice.",
    image: dapp,
  },
  {
    title: "Security Audits",
    description:
      "We provide comprehensive security audits for smart contracts and DApps. " +
      "Our team of experts will identify vulnerabilities and provide recommendations to ensure your project is secure." +
      "We also offer ongoing support to keep your project secure and up-to-date.",
    image: w3sec,
  },
  {
    title: "Industrial Use Cases",
    description:
      "Our team has experience working with a wide range of industries, including finance, healthcare, and supply chain. " +
      "We can help you leverage blockchain technology to optimize your business processes and create new revenue streams." +
      "Our solutions are tailored to your specific needs and designed to drive innovation and growth.",
    image: industry,
  },
];
export default w3cServices;
