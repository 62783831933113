import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";
import { CookieContext } from "./context/CookieContext";

const CookieConsent = () => {
  const { cookies, setCookie } = useContext(CookieContext);
  const [open, setOpen] = useState(!cookies.cookiesAccepted);
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.cookiesAccepted === undefined) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [cookies.cookiesAccepted]);

  const handleDecline = () => {
    setCookie("cookiesAccepted", false);
    setOpen(false);
  };

  const handleAccept = () => {
    setCookie("cookiesAccepted", true, { expires: 60 });
    setOpen(false);
  };

  const handlePrivacyPolicy = () => {
    navigate("/privacy-policy");
  };

  return (
    open && (
      <Box sx={styles.popOutBox}>
        <Box sx={styles.header}>
          {/*  <img src={logo} alt="Logo" style={styles.logo} /> */}
          <Typography variant="h6">This website uses cookies.</Typography>
        </Box>
        <Box sx={styles.content}>
          <Typography variant="body2">
            We use cookies to improve your experience on our site, to collect
            statistics for analyzing how the site is used, and to show
            individually tailored ads on social media and other websites you
            visit. By clicking "Accept all cookies", you consent to Web 3.0
            Connect's and third parties' use of cookies on this site. You can
            also set your own preferences for cookies by checking the desired
            boxes and selecting "Accept selected cookies". Read our{" "}
            <button
              onClick={handlePrivacyPolicy}
              style={{
                color: "primary",
                textDecoration: "underline",
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
                fontSize: "inherit",
                fontFamily: "inherit",
              }}
            >
              Privacy notice.
            </button>
          </Typography>
        </Box>
        <Box sx={styles.actions}>
          <Button variant="contained" color="primary" onClick={handleAccept}>
            Accept
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#FF8A7C",
              borderColor: "#FF8A7C",
              "&:hover": {
                backgroundColor: "#5A768B",
                borderColor: "#5A768B",
                color: "primary",
              },
            }}
            onClick={handleDecline}
          >
            Decline
          </Button>
        </Box>
      </Box>
    )
  );
};

const styles = {
  popOutBox: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    maxHeight: "40%",
    backgroundColor: "#8D9196",
    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    padding: "20px",
    boxSizing: "border-box",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  logo: {
    maxWidth: "40px",
    marginRight: "10px",
  },
  content: {
    marginTop: "10px",
    textAlign: "left",
  },
  actions: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    gap: { xs: "5%", sm: "5%", md: "4%", lg: "2%" },
  },
  button: {
    fontSize: "1rem",
  },
};

export default CookieConsent;
