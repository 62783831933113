import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  ListItemButton,
} from "@mui/material";
import theme from "../theme";
import logo from "../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom";

const drawerWidth = "100vw";
const logoWidth = "6.5vh";
const navItems = ["Who we are!", "Get in touch"];

function Navbar(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        alignItems: "right",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <img
        src={logo}
        alt="W3C Logo"
        style={{
          display: "inline",
          maxHeight: logoWidth,
          marginRight: "1.25%",
        }}
        onClick={() => navigate("/")}
      />
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "left" }}>
              <Link
                to={`/${item.replace(/\s+/g, "-").toLowerCase()}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItemText primary={item} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    typeof window !== "undefined" ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        style={{
          backgroundColor: scrolled
            ? theme.palette.background.default
            : "transparent",
          alignItems: "left",
          fontWeight: "500",
          transition: "background-color 0.3s",
          boxShadow: scrolled ? theme.shadows[4] : "none",
        }}
        color="primary"
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logo}
            alt="Logo"
            style={{
              display: "inline",
              maxHeight: logoWidth,
              marginRight: "1%",
            }}
            onClick={() => navigate("/")}
          />
          <Typography
            variant="h7"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "block", sm: "block" },
              textAlign: { xs: "center", sm: "left" },
              maxWidth: { xs: "50%", sm: "30%", md: "18%", lg: "13%" },
              borderRight: {
                xs: "none",
                sm: "none",
                md: "1.5px inset #8D9196",
              },
              marginRight: { sm: "2.5%", md: "1%" },
            }}
            color="primary"
          >
            <Link to={`/`} style={{ textDecoration: "none", color: "inherit" }}>
              Web 3.0 Connect{" "}
            </Link>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item}
                sx={{
                  color: theme.palette.primary.main,
                  backgroundColor: scrolled
                    ? theme.palette.background.default
                    : "transparent",
                  "&:hover": {
                    backgroundColor: theme.palette.background.default,
                  },
                }}
              >
                <Link
                  to={`/${item.replace(/\s+/g, "-").toLowerCase()}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {item}
                </Link>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            textAlign: "center",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: theme.palette.background.default, // Set background color for the Drawer paper
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default Navbar;
