function Http(baseURL) {
  return {
    async request(endpoint, options) {
      const response = await fetch(`${baseURL}${endpoint}`, {
        ...options,
        headers: {
          "Content-Type": "application/json",
          ...options.headers,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(
          "Network response was not ok:",
          response.status,
          errorText
        );
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      return await response.json();
    },
    async get(endpoint, headers = {}) {
      return this.request(endpoint, {
        method: "GET",
        headers,
      });
    },
    async post(endpoint, data, headers = {}) {
      return this.request(endpoint, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      });
    },
    async put(endpoint, data, headers = {}) {
      return this.request(endpoint, {
        method: "PUT",
        headers,
        body: JSON.stringify(data),
      });
    },
    async delete(endpoint, headers = {}) {
      return this.request(endpoint, {
        method: "DELETE",
        headers,
      });
    },
  };
}
export { Http };
