import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { postUserData } from "./utils/apiUser";
import { CookieContext } from "./context/CookieContext";

import {
  TextField,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const Contact = () => {
  const { cookies, setCookie } = useContext(CookieContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tlf: "",
    comment: "",
    language: "en",
  });

  const [errors, setErrors] = useState({});
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();

  const validateInput = (name, value) => {
    let error = "";
    if (name === "name" || name === "surname") {
      if (!value) {
        error = "This field is required";
      } else if (/[^a-zA-ZæøåÆØÅ]/.test(value)) {
        error = "Invalid characters";
      }
    } else if (name === "email") {
      if (!value) {
        error = "This field is required";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        error = "Invalid email address";
      }
    } else if (name === "tlf") {
      if (value && !/^\d+$/.test(value)) {
        error = "Invalid phone number";
      }
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validateInput(name, value);
    setErrors({ ...errors, [name]: error });
    setFormData({ ...formData, [name]: value });
  };

  const handlePrivacyChange = (e) => {
    setPrivacyChecked(e.target.checked);
  };

  const sendUserData = (e) => {
    e.preventDefault();
    postUserData(formData);
    if (cookies.cookiesAccepted) {
      setCookie("formData", JSON.stringify(formData), { expires: 60 });
    }
  };

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        navigate("/");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [submitted, navigate]);

  const isFormValid = () => {
    return (
      formData.name &&
      formData.email &&
      !errors.name &&
      !errors.email &&
      privacyChecked
    );
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "primary",
        flexDirection: { xs: "column", md: "column", lg: "row" },
        pt: { xs: 8, md: 4, lg: 4 },
      }}
    >
      <Box
        sx={{
          flex: 1,
          padding: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          maxWidth: { xs: "100%", md: "80%", lg: "45%" },
          maxHeight: "fit-content",
        }}
      >
        <Typography variant="h4" sx={{ color: "primary", mb: 2 }}>
          Let's Connect!
        </Typography>
        <Typography variant="body1" sx={{ color: "secondary", mb: 2 }}>
          We would love to hear from you! Whether you have a question about
          features, trials, pricing, need a demo, or anything else, our team is
          ready to answer all your questions.
        </Typography>
        <Typography variant="body1" sx={{ color: "secondary", mb: 2 }}>
          By reaching out to us, you are taking the first step towards a
          successful collaboration. Our experts are here to provide you with the
          best solutions tailored to your needs. Don't hesitate to get in touch
          and let us help you achieve your goals.
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: { xs: "100%", md: "90%", lg: "45%" },
          maxHeight: "fit-content",
        }}
      >
        {submitted ? (
          <Typography
            variant="h5"
            sx={{ color: "primary", textAlign: "center" }}
          >
            Thank you!
          </Typography>
        ) : (
          <form
            onSubmit={(e) => {
              sendUserData(e);
              setSubmitted(true);
            }}
            ref={formRef}
          >
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              fullWidth
              margin="normal"
              slotProps={{ htmlInput: { maxLength: 50 } }}
              sx={{ color: "red" }}
            />
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              fullWidth
              margin="normal"
              slotProps={{ htmlInput: { maxLength: 50 } }}
            />
            <TextField
              label="Phone"
              name="tlf"
              value={formData.tlf}
              onChange={handleChange}
              error={!!errors.tlf}
              helperText={errors.tlf}
              fullWidth
              margin="normal"
              slotProps={{ htmlInput: { maxLength: 15 } }}
            />
            <TextField
              label="Comment"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
              margin="normal"
              slotProps={{ htmlInput: { maxLength: 300 } }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacyChecked}
                  onChange={handlePrivacyChange}
                  name="privacyPolicy"
                  style={{ color: "#A66CFF" }}
                />
              }
              label={
                <Typography variant="body2">
                  I agree to the{" "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#A66CFF" }}
                  >
                    privacy policy
                  </a>
                </Typography>
              }
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isFormValid()}
            >
              Submit
            </Button>
          </form>
        )}
      </Box>
    </Box>
  );
};

export default Contact;
