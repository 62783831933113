import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import home from "../assets/home.svg";

const Home = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        color: "primary",
        flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" },
        maxWidth: { xs: "95%", md: "85%", lg: "90%" },
        margin: "auto",
        pt: { xs: 0, md: 0 }, // Add padding-top for smaller screens
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "95%", md: "85%", lg: "50%" },
        }}
      >
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          color="primary"
          sx={{
            fontSize: { xs: "2.5rem", md: "4.5rem", lg: "2.8rem" },
          }}
        >
          Build your next{" "}
          <Box
            component="span"
            sx={{
              fontWeight: "bold",
              backgroundColor: "#23273D",
              color: "#F2F5F7",
              px: 1,
            }}
          >
            Web 3.0
          </Box>{" "}
          project!
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="primary"
          sx={{
            fontSize: { xs: "1.5rem", md: "3rem", lg: "1.5rem" },
          }}
        >
          Supercharge your Web 3.0 ambitions with our cutting-edge Connect
          solutions, designed to elevate your projects to the next level.
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/get-in-touch")}
          sx={{ mt: 4 }}
        >
          Get Started
        </Button>
      </Container>
      <Box
        component="img"
        src={home}
        alt="Web3 Logo"
        sx={{
          width: { xs: "95%", md: "85%", lg: "50%" },
          height: "auto",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          pr: { xs: 0, md: 2, lg: 4 }, // Add padding-right for larger screens
        }}
      />
    </Box>
  );
};

export default Home;
